<template>
	<v-sheet flat>
		<h2>Incentives Only Projects</h2>

		<v-card class="rounded-lg" color="lightest">
			<v-card-text>
				<p>
					Use the filters to search for past, present and future
					incentives submissions for your organisation.
				</p>
				<filter-appointments v-model="filter" :project-items="projectItems"></filter-appointments>
			</v-card-text>
		</v-card>
		<v-text-field label="Search" append-icon="mdi-magnify"></v-text-field>

		<v-data-table :loading="loading" :items="incentiveSubmissions" :headers="headers">
			<template v-slot:item.project="{ item }">
				<project-name :value="item.project"></project-name>
			</template>
			<template v-slot:item.date_paid="{ item }">
				<date :time="false"
					:value="item.date_paid"
				></date>
			</template>
			<template v-slot:item.created_at="{ item }">
				<date :time="false"
					:value="item.created_at"
				></date>
			</template>
			<template v-slot:item.ready_to_pay_at="{ item }">
				<date :time="false"
					:value="item.ready_to_pay_at"
				></date>
			</template>
			<template v-slot:footer.prepend>
				<export-button
					:columns="headers"
					:rows="downloadRows"
					name="MW-Export"
				></export-button>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import FilterAppointments from "@c/contracts/Filter";
import ExportButton from "@c/contracts/Export";
import Date from "@c/contracts/Date";
import ProjectName from "@c/contracts/ProjectName";
export default {
	name: "Incentives",
	data: () => {
		return {
			loading: false,
			filter: {
				projects: [],
				start: null,
				end: null,
				appointment_status: null,
			},
		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		projectItems() {
			return Object.values(this.$store.state.projects.data)
				.filter((p) => p.incentive_only)
				.map((p) => {
					return {
						value: p.id,
						text: p.client_name,
					};
				});
		},
		incentiveSubmissions() {
			return this.$store.getters["incentiveSubmissions/getByFilters"](
				this.filter
			);
		},
		headers() {
			return [
				{ value: "project", text: "Project" },
				{ value: "first_name", text: "Name"},
				{ value: "status", text: "Status" },
				{ value: "amount", text: "Amount" },
				{ value: "created_at", text: "Created"},
				{ value: "ready_to_pay_at", text: "Ready Date"},
				{ value: "date_paid", text: "Paid Date"}
			];
		},
		projects() {
			return this.$store.state.projects.data;
		},
		downloadRows() {
			const self = this;
			let rows = self.incentiveSubmissions;
			rows = rows.map((r) => {
				return {
					project: self.getProjectName(r),
					status: r.status,
					created_at: r.created_at ? r.created_at.toDate().toLocaleDateString() : "",
					ready_to_pay_at: r.ready_to_pay_at ? r.ready_to_pay_at.toDate().toLocaleDateString() : "",
					date_paid: r.date_paid ? r.date_paid.toDate().toLocaleDateString() : "",
				}
			});
			return rows;
		},
	},
	components: {
		FilterAppointments,
		ExportButton,
		Date,
		ProjectName,
	},
	watch: {
		organisation: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$store.dispatch("projects/fetchAll", true);
				}
			},
		},
		filter() {
			this.loading = true;
			this.$store
				.dispatch("incentiveSubmissions/fetchByProjects", this.filter.projects )
				.then(() => {
					this.loading = false;
				});
		},
	},
	methods: {
		getProjectName(item) {
			if (!item.project) {
				return "";
			}
			if (!this.projects[item.project]) {
				return "";
			}
			return this.projects[item.project].client_name || "";
		},
		getStart(item) {
			try {
				let date = { ...item.start };
				date = date.toDate();
				return `${date.toLocaleDateString()} - ${date.toLocaleTimeString(
					{},
					{ hour: "2-digit", minute: "2-digit" }
				)}`;
			} catch (error) {
				return "";
			}
		},
	},
};
</script>
